import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useSeoContent from '@/utils/useSeoContent';

function Head({ location }) {
  const { pathname } = location;
  const { siteMeta, items, file } = useSeoContent();
  const {
    tabName = '404: Not found',
    keywords = [],
    description,
    author,
    date,
    type,
  } = useMemo(() => items.find(({ to }) => to === pathname || to.slice(0, -1) === pathname) || {}, [items, pathname]);
  const kws = useMemo(() => (keywords.length > 0 ? keywords.join(',') : ''), [keywords]);
  const baseUrl = process.env.GATSBY_URL;

  return (
    <>
      <title>{tabName}</title>
      <meta name="title" property="og:title" content={tabName} />
      <meta name="description" property="og:description" content={description || siteMeta.description} />
      <meta property="og:url" content={`${baseUrl}${pathname}`} />
      <meta property="og:site_name" content={siteMeta.title} />
      <meta property="og:type" content={type || 'website'} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteMeta.author} />
      <meta name="twitter:site" content={baseUrl} />
      <meta name="twitter:title" content={tabName} />
      <meta name="twitter:description" content={description || siteMeta.description} />
      {type === 'article' && author && <meta property="article:author" content={author} />}
      {type === 'article' && date && <meta property="article:published_time" content={date} />}
      {type === 'article' && date && <meta property="article:modified_time" content={date} />}
      {type === 'article' && <meta property="article:section" content="Gesundheit" />}
      {kws && <meta name="keywords" content={kws} />}
      {file && <meta property="og:image" content={baseUrl + file} />}
      {file && <meta property="og:image:secure_url" content={baseUrl + file} />}
      <meta property="og:image:alt" content="Logo Augenmobil" />
      {file && <meta name="twitter:image" content={baseUrl + file} />}
      {file && <meta name="twitter:image:src" content={baseUrl + file} />}
      <link rel="canonical" href={`${baseUrl}${pathname}`} />
    </>
  );
}

Head.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Head;
