import React from 'react';

export default () => (
  <svg height="80" viewBox="0 0 80 80" width="80" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="#bcbcbc" strokeWidth="1.5">
        <path d="m16.551 42.322s6.728-15.056 24.186-14.736c17.46.32 24.185 14.736 24.185 14.736s-7.367 13.614-24.185 14.415c-16.818.8-24.186-14.415-24.186-14.415z" />
        <path d="m54.185 42.174c0 7.882-6.39 14.271-14.271 14.271-7.882 0-14.272-6.389-14.272-14.27 0-7.883 6.39-14.272 14.272-14.272 7.881 0 14.271 6.39 14.271 14.271z" />
        <path d="m47.022 42.174a7.108 7.108 0 1 1 -14.216 0 7.108 7.108 0 0 1 14.216 0z" />
      </g>
      <path
        d="m34.343 30.32.006-.003-6.066-15.913-5.913 15.526a.04.04 0 0 0 -.005.01l-.019.054-.127.332.006.003a6.383 6.383 0 0 0 -.348 2.068 6.409 6.409 0 1 0 12.818 0c0-.728-.128-1.426-.352-2.078"
        fill="#fff"
      />
      <path
        d="m34.343 30.32.006-.003-6.066-15.913-5.913 15.526a.04.04 0 0 0 -.005.01l-.019.054-.127.332.006.003a6.383 6.383 0 0 0 -.348 2.068 6.409 6.409 0 1 0 12.818 0c0-.728-.128-1.426-.352-2.078z"
        stroke="#bcbcbc"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
