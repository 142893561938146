/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { LayoutContext } from '@/components/Layout';

import { boxVariants } from './utils';
import useStyles from './styles';

const Item = ({ title, icon, texts }) => {
  const { setShowIntro } = useContext(LayoutContext);
  const classes = useStyles();
  const [pose, setPose] = useState('close');

  const toShowIntro = useCallback(
    (e) => {
      const { url } = e.target.dataset || {};

      if (url) {
        setShowIntro(url);
      }
    },
    [setShowIntro]
  );

  const handleClick = useCallback(() => {
    setPose(pose === 'close' ? 'open' : 'close');
  }, [pose]);
  const Icon = icon;

  return (
    <div className={classes.container}>
      <button className={classes.button} type="button" onClick={handleClick}>
        <p className={classes.title}>{title}</p>
        <Icon />
        <p className={classes.sign}>{pose === 'close' ? '+' : '-'}</p>
      </button>
      <motion.div className={classes.box} variants={boxVariants} animate={pose} initial="close">
        <div className={classes.contentContainer}>
          {texts.map((text, i) => {
            if (text.link) {
              const { before, link = {}, after } = text;

              return (
                <p key={i} className={classes.text}>
                  {before}{' '}
                  <a href={link.url} target={link.target} className={classes.link} rel="noopener noreferrer">
                    {link.name}
                  </a>{' '}
                  {after}
                </p>
              );
            }

            if (text.intro) {
              const { url, name } = text.intro || {};

              return (
                <button key={i} type="button" data-url={url} className={classes.buttonLink} onClick={toShowIntro}>
                  {name}
                </button>
              );
            }

            if (text.title)
              return (
                <p key={i} className={classes.subtitle}>
                  {text.title}
                </p>
              );

            return (
              <p key={i} className={classes.text}>
                {text.text}
              </p>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  texts: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        before: PropTypes.string.isRequired,
        link: PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          target: PropTypes.string.isRequired,
        }).isRequired,
        after: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        intro: PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ]).isRequired
  ).isRequired,
};

export default Item;
