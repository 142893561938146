import { createUseStyles } from 'react-jss';

export default createUseStyles(({ components, spacing, colors, fonts }) => {
  const { background } = components.dropDown;
  const { primary, hover, click } = colors.link;

  return {
    container: {
      margin: [spacing * 2, spacing * 2, 0, spacing * 2],
    },
    button: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      height: spacing * 11,
      width: '100%',
      padding: [0, spacing * 5],
      marginTop: spacing * 1.5,
      background,
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      '@media (max-width: 799px)': {
        justifyContent: 'space-between',
        padding: [0, spacing * 2],
      },
    },
    title: {
      flex: [1, 1, 'auto'],
      ...fonts.subtitle,
      textAlign: 'left',
      '@media (max-width: 799px)': {
        ...fonts.subtitleMedium,
      },
    },
    sign: {
      ...fonts.subtitleSmall,
      width: 10,
      marginLeft: spacing * 4,
      '@media (max-width: 799px)': {
        ...fonts.subtitleMedium,
        marginLeft: 0,
      },
    },
    subtitle: {
      ...fonts.subtitleBold,
      padding: [spacing * 2, 0, 0],
      width: '100%',
      maxWidth: 460,
      textAlign: 'left',
    },
    text: {
      maxWidth: 460,
      ...fonts.subtitle,
    },
    box: {
      overflow: 'hidden',
      height: 0,
      opacity: 0,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: [spacing * 3, 0, spacing, 0],
    },
    link: {
      textDecoration: 'none',
      ...fonts.subtitle,
      color: primary,
      '&:hover': {
        color: hover,
      },
      '&:active': {
        color: click,
      },
      '@media (max-width: 799px)': {
        color: primary,
      },
    },
    buttonLink: {
      ...fonts.subtitle,
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      color: primary,
      padding: 0,
      marginTop: spacing * 2,
      width: '100%',
      maxWidth: 460,
      textAlign: 'left',
      '&:hover': {
        color: hover,
      },
      '&:active': {
        color: click,
      },
      '@media (max-width: 799px)': {
        color: primary,
      },
    },
  };
});
