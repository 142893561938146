import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import useStyles from './styles';

const PracticeList = ({ name, items }) => {
  const classes = useStyles();

  return (
    <>
      <p className={classes.text}>{name}</p>
      <div className={classes.container}>
        {items.map(({ title, ...rest }) => (
          <Item key={title} title={title} {...rest} />
        ))}
      </div>
    </>
  );
};

PracticeList.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PracticeList;
