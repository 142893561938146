export default ({ colors, spacing, fonts }) => {
  const { text: textColors } = colors;

  return {
    container: {
      margin: [spacing * 2, spacing * 2, 0, spacing * 2],
    },
    text: {
      margin: 0,
      ...fonts.title,
      color: textColors.desktop,
      '@media (max-width: 799px)': {
        ...fonts.title['@media (max-width: 799px)'],
        color: colors.text.mobile,
      },
    },
  };
};
