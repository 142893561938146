import { useStaticQuery, graphql } from 'gatsby';

import Hornhaut from '@/icons/Hornhaut';
import Injection from '@/icons/Injection';
import Operative from '@/icons/Operative';

const icons = {
  hornhaut: Hornhaut,
  injection: Injection,
  operative: Operative,
};

export default () => {
  const { praxisJson } = useStaticQuery(graphql`
    query PraxisPageQuery {
      praxisJson(praxis: { id: { eq: "praxis" } }) {
        praxis {
          title
          items {
            texts {
              title
              after
              before
              link {
                name
                target
                url
              }
              text
              intro {
                name
                url
              }
            }
            title
            icon
          }
          description
          id
          subtitle
        }
      }
    }
  `);

  const { items, ...other } = praxisJson.praxis;

  return {
    items: items.map(({ icon, ...rest }) => ({ icon: icons[icon], ...rest })),
    ...other,
  };
};
