import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, fonts, components }) => ({
  container: {
    paddingBottom: components.curtain.height * 1.5,
  },
  text: {
    ...fonts.subtitleBold,
    margin: [spacing * 4, spacing * 2, spacing * 2],
    paddingBottom: spacing,
    '@media (max-width: 799px)': {
      display: 'none',
    },
  },
}));
