import React from 'react';

import usePraxisPageContent from '@/utils/usePraxisPageContent';
import Description from '@/components/Description';
import PracticeList from '@/components/PracticeList';

const Praxis = () => {
  const { description, subtitle, title, items } = usePraxisPageContent();

  return (
    <>
      <Description title={title} text={description} />
      <PracticeList name={subtitle} items={items} />
    </>
  );
};

export default Praxis;
export { default as Head } from '@/components/Head';
