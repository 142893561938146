import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Title = ({ className, classes, children }) => <p className={cn(className, classes.title)}>{children}</p>;

Title.defaultProps = {
  className: null,
};

Title.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.string.isRequired,
};

export default Title;
