import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#bcbcbc"
        strokeWidth="1.5"
        d="M40.03 28.361c9.596 0 17.373 7.446 17.373 16.632 0 9.185-7.777 16.632-17.373 16.632-9.597 0-17.375-7.447-17.375-16.632 0-9.186 7.778-16.632 17.374-16.632z"
      />
      <path
        stroke="#bcbcbc"
        strokeWidth="1.5"
        d="M53.123 34.118c.553 1.011.855 2.103.855 3.242 0 5.204-6.306 9.424-14.084 9.424-7.78 0-14.085-4.22-14.085-9.424 0-.976.221-1.918.632-2.803"
      />
      <path
        stroke="#bcbcbc"
        strokeWidth="1.5"
        d="M40.001 31.12c4.071 0 7.371 2.357 7.371 5.265 0 2.907-3.3 5.264-7.37 5.264s-7.37-2.357-7.37-5.264c0-2.908 3.3-5.265 7.37-5.265z"
      />
      <path fill="#FFF" d="M31.878 21.811l9.527 13.885-7.726-4.213-5.15-7.348" />
      <path stroke="#bcbcbc" strokeLinejoin="bevel" strokeWidth="1.5" d="M31.878 21.811l9.527 13.885-7.726-4.213-5.15-7.348" />
      <path
        stroke="#bcbcbc"
        strokeLinejoin="bevel"
        strokeWidth="1.5"
        d="M23.273 5.282l10.442 15.274-1.837 1.255-5.023 3.435L16.413 9.972"
      />
    </g>
  </svg>
);
