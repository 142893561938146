import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      appJson(menu: { id: { eq: "menu" } }) {
        menu {
          augenmobil {
            to
            tabName
            description
            keywords
          }
          praxis {
            tabName
            to
            description
            keywords
          }
        }
      }
      augenmobilJson(press: { id: { eq: "press" } }) {
        press {
          items {
            author
            date
            text
            title
            route
          }
        }
      }
      file(relativePath: { eq: "augenmobil_social_media.png" }) {
        publicURL
      }
    }
  `);

  const pressItems = useMemo(
    () =>
      data.augenmobilJson.press.items.map(({ title, route, author, date, text }) => ({
        tabName: `${title} | Augenmobil`,
        to: `/augenmobil/news/${route}/`,
        description: text.join(' '),
        author,
        date,
        type: 'article',
      })),
    [data.augenmobilJson.press.items]
  );

  const items = useMemo(
    () => [
      ...data.appJson.menu.augenmobil,
      ...data.appJson.menu.praxis.slice(1),
      ...pressItems,
      {
        tabName: 'Häufige Fragen | Augenmobil',
        to: '/augenmobil/faq/',
        description: 'Erfahren Sie mehr über Augenmobil und finden Sie Antworten zu häufigen Fragen.',
      },
    ],
    [data.appJson.menu.augenmobil, data.appJson.menu.praxis, pressItems]
  );

  return {
    siteMeta: data.site.siteMetadata,
    items,
    file: data.file.publicURL,
  };
};
