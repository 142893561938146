import React from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';

const Description = ({ title, text, classes }) => (
  <div className={classes.container}>
    <Title>{title}</Title>
    <p className={classes.text}>{text}</p>
  </div>
);

Description.defaultProps = {
  title: '',
  text: '',
};

Description.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Description;
