import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd" stroke="#bcbcbc" strokeWidth="1.5">
      <path d="M64.898 39.982c0 9.655-4.643 17.482-10.369 17.482s-10.368-7.827-10.368-17.482S48.803 22.5 54.529 22.5s10.369 7.827 10.369 17.482z" />
      <path d="M49.764 40.331c0 9.596-7.446 17.374-16.632 17.374-9.185 0-16.633-7.777-16.633-17.373s7.448-17.375 16.633-17.375c9.186 0 16.632 7.78 16.632 17.374z" />
      <path d="M44.006 53.425c-1.01.554-2.103.855-3.24.855-5.206 0-9.426-6.306-9.426-14.084 0-7.78 4.22-14.085 9.425-14.085.975 0 1.917.22 2.802.633" />
      <path d="M47.004 40.303c0 3.095-1.79 5.604-4.002 5.604-2.21 0-4.002-2.509-4.002-5.604s1.792-5.603 4.002-5.603c2.211 0 4.002 2.508 4.002 5.603z" />
    </g>
  </svg>
);
